import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'

import { Link } from 'gatsby'


const styles = (theme) => {
  return createStyles({
    button: {
      [theme.breakpoints.up('xs')]: {
        padding: '2px',
        minWidth: '5rem',
        height: "30px",
        fontSize: "12px"
      },
      [theme.breakpoints.up('md')]: {
        padding: '0.425rem',
        minWidth: '6rem',
        height: "35px",
        fontSize: "14px"
      },
      fontFamily: 'Montserrat',
      backgroundColor: '#FF0080',
      color: '#fff',
      borderRadius: 100,
      border: 0,
      minWidth: '5.9375rem',
      padding: '0.625rem',
      cursor: 'pointer',
      fontWeight: '700',
      fontSize: 14,
    },
  })
}

const SignUpButton = ({ classes, onOpen, openQrModal }) => {
  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    setIsPhone(window.innerWidth <= 599)
    window.addEventListener("resize", handleResize);
  }, [])

  const handleResize = (e) => {
    setIsPhone(window.innerWidth <= 599)
  }

  let result = (
    <Link disabled={!isPhone} to={isPhone ? 'https://avocadofinance-alternate.app.link' : null} className={classes.link}>
      <button
        disabled={isPhone}
        onClick={() => {
          openQrModal()
        }}
        className={classes.button}
      >
        Sign Up
      </button>
    </Link>
  )

  if (isPhone) {
    result = (
      <Link to={isPhone ? 'https://avocadofinance-alternate.app.link' : null} className={classes.link}>
        <button
          className={classes.button}
        >
          Sign Up
        </button>
      </Link>
    )
  }

  return result
}


export default withStyles(styles)(SignUpButton)
