import React from 'react'
import { Link } from 'gatsby'

const PageLink = ({ to, Component, className, activeClassName, ...other }) => (
  <Link to={to} className={className} activeClassName={activeClassName} target={other.target}>
    <Component {...other} href={to} />
  </Link>
)

export default PageLink
