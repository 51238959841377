import { createStyles, withStyles } from '@material-ui/core'

import { StoreLink, getStoreLinkStyles } from '../../../StoreLinks/StoreLink'
import { flexBreakpoint } from '../Top/styles'

const styles = (theme) => {
  const storeLinkStyles = getStoreLinkStyles(theme)
  return createStyles({
    ...storeLinkStyles,
    button: {
      ...storeLinkStyles.button,
      maxWidth: '8.1875rem',
      width: 'calc(50% - 1rem)',
      [theme.breakpoints.up('md')]: {
        width: '5.625rem',
      },
      '& + &': {
        ...storeLinkStyles.button['& + &'],
        [theme.breakpoints.up(flexBreakpoint)]: {
          marginTop: '0.5rem',
          marginLeft: 0,
        },
      },
    },
  })
}

export default withStyles(styles)(StoreLink)
