import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'

import { Section, getSectionStyles } from '../Section'
import StoreLinks from '../../../StoreLinks'
import StoreLink from './StoreLink'
import { flexBreakpoint } from '../Top/styles'

const styles = (theme) => {
  const sectionStyles = getSectionStyles(theme)
  const breakpointMdUp = theme.breakpoints.up('md')
  return {
    ...sectionStyles,
    section: {
      ...sectionStyles.section,
      marginBottom: '3.9925rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'auto',
      [theme.breakpoints.up(flexBreakpoint)]: {
        width: 'auto',
        alignItems: 'flex-start',
        marginRight: '3rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: '4.6875rem',
      },
    },
    list: {
      display: 'flex',
      padding: 0,
      margin: 0,
      width: '100%',
      justifyContent: 'center',
      [theme.breakpoints.up(flexBreakpoint)]: {
        flexDirection: 'column',
      },
    },
    title: {
      ...sectionStyles.title,
      fontSize: '0.875rem',
      [breakpointMdUp]: {
        ...sectionStyles.title[breakpointMdUp],
        fontSize: '0.75rem',
      },
    },
  }
}

const ComingSoon = ({ classes }) => {
  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    setIsPhone(window.innerWidth <= 599)
    window.addEventListener("resize", handleResize);
  }, [])

  const handleResize = (e) => {
    setIsPhone(window.innerWidth <= 599)
  }


  return (
    <Section title={isPhone ? '' : 'Download'} classes={classes}>
      <ul className={classes.list}>
        <StoreLinks Item={StoreLink} />
      </ul>
    </Section>
  )
}


export default withStyles(styles)(ComingSoon)
