import React from 'react'
import { links } from '../../../constants/links'

import Section from './Section'
import Links from './Links'
import Link from './Links/Item'

const Product = () => (
  <Section title="Product">
    <Links>
      <Link to={links.features}>Features</Link>
      <Link to={links.download}>Download</Link>
      <Link to={links.pricing}>Pricing</Link>
    </Links>
  </Section>
)

export default Product
