import React, { useState, useEffect } from 'react'
import {
    Container,
    Grid,
    createStyles,
    withStyles,
    Typography,
    Button
} from '@material-ui/core'
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from 'gatsby'
const footerMarginTop = '6.0625rem'
const paddingY = '0.875rem'

const paddingX = '1.25rem'

const styles = (theme) =>
    createStyles({
        WrapperSection: {
            backgroundColor: "#101010",
            color: "#fff",
            // padding: "3em 0",
            // height:"400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: footerMarginTop,
            padding: "90px 30px",
            // [theme.breakpoints.up('xs')]: {
            //     marginTop: '100px',
            // },
            [theme.breakpoints.up('md')]: {
                marginTop: '300px',
            },
        },
        container: {
            textAlign: "center",
            [theme.breakpoints.up('xs')]: {
                maxWidth: '77.625rem',
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: '28rem',
                padding: 0,
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '58rem',
                padding: 0,
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '77.625rem',
            },
        },
        title: {
            //   marginBottom: '2.875rem',
            color: '#F0EDEF',
            fontWeight: 800,
            lineHeight: '1.32',
            [theme.breakpoints.up('xs')]: {
                fontSize: '30px',
                // marginBottom: '7.0625rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '52px',
                // marginTop: '9.8125rem',
            },
            [theme.breakpoints.up('lg')]: {
                // fontSize: '2.25rem',
                // marginBottom: '7.375rem',
            },
        },
        content: {
            fontSize: '0.875rem',
            margin: '1em auto',
            color: "rgba(255, 255, 255, 0.7)",
            [theme.breakpoints.up('xs')]: {
                fontSize: '18px',
                width: "100%"
                // marginBottom: '7.0625rem',
            },
            [theme.breakpoints.up('md')]: {
                width: "85%",
                fontSize: '32px',
                // marginBottom: '7.0625rem',
            },
            [theme.breakpoints.up('lg')]: {
                width: "65%"
                // fontSize: '2.25rem',
                // marginBottom: '7.375rem',
            },
        },
        row: {
            '& + &': {
                [theme.breakpoints.up('md')]: {
                    // marginTop: '10rem',
                },
                [theme.breakpoints.up('lg')]: {
                    // marginTop: '9.75rem',
                },
            },
        },
        btns: {
            [theme.breakpoints.up('md')]: {
                marginTop: '1rem',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '1.5rem',
            },
        },
        link: {
            textDecoration: "none",
            color: "#fff",
            marginTop: "15px"
        },
        button: {
            [theme.breakpoints.up('lg')]: {
                borderRadius: '6.25rem',
                fontWeight: 700,
                fontSize: '0.875rem',
                lineHeight: '17px',
                textAlign: 'center',
                marginLeft: '0.75rem',
                boxShadow: 'none',
                paddingLeft: paddingX,
                paddingRight: paddingX
            },
            [theme.breakpoints.up('md')]: {
                borderRadius: '6.25rem',
                fontWeight: 700,
                fontSize: '0.875rem',
                lineHeight: '17px',
                textAlign: 'center',
                marginLeft: '0.75rem',
                boxShadow: 'none',
                paddingLeft: paddingX,
                paddingRight: paddingX,
            },
            borderRadius: '6.25rem',
            fontWeight: 700,
            fontSize: '0.875rem',
            lineHeight: '17px',
            textAlign: 'center',
            marginLeft: '0.75rem',
            boxShadow: 'none',
            paddingLeft: paddingX,
            paddingRight: paddingX,
            width: 192,
            height: "40px",
            marginTop: "20px"
        },
    })

const Contact = ({ classes, setModalIsOpen }) => {
    const [isPhone, setIsPhone] = useState(false)

    useEffect(() => {
        setIsPhone(window.innerWidth <= 599)
        window.addEventListener("resize", handleResize);
    }, [])

    const handleResize = (e) => {
        setIsPhone(window.innerWidth <= 599)
    }

    return (
        <section className={classes.WrapperSection}>
            <Container className={classes.container}>
                <ScrollAnimation animateIn="fadeIn" animateOnce>
                    <Typography align="center" className={classes.title} variant="h3">
                        Smartest Way to Manage Your Finances
                    </Typography>
                    <Typography align="center" className={classes.content}>
                        Avocado Finance provides the necessary tools to manage your finances on your own.
                    </Typography>
                    <div className={classes.btns}>
                        <Link disabled={!isPhone} to={isPhone ? 'https://avocadofinance-alternate.app.link' : null} className={classes.link}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (isPhone) return
                                    setModalIsOpen()
                                }}
                            >
                                Sign Up
                            </Button>
                        </Link>
                        <Link to="/features" className={classes.link}>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                color="inherit"
                            >Browse Features
                            </Button>
                        </Link>
                    </div>
                </ScrollAnimation>
            </Container>
        </section>
    )
}

export default withStyles(styles)(Contact)
