import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import Social from './Social'
import BaseLogo from './Logo'
import { flexBreakpoint } from './styles'

const styles = (theme) => {
  return createStyles({
    section: {
      marginBottom: '4.1875rem',
      width: '100%',
      [theme.breakpoints.up(flexBreakpoint)]: {
        marginBottom: '6.1875rem',
        marginBottom: 0,
        flexBasis: '10.0625rem',
        marginRight: '1.875rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '6.1875rem',
        marginRight: '4.875rem',
      },
    },
  })
}

const Top = ({ classes, Logo = BaseLogo }) => {
  return (
    <div className={classes.section} align="center">
      <Logo />
      <Social />
    </div>
  )
}

export default withStyles(styles)(Top)
