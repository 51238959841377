import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import PageLink from '../../../../PageLink'

import { navPadding } from '../..'

const textStyles = (theme) => {
    return createStyles({
        text: {
            fontWeight: 'inherit',
            fontSize: '1rem',
            padding: `0 1rem ${navPadding.verticalMd}`,
            borderBottom: `0.0625rem solid transparent`,
            display: 'flex',
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    })
}

const Text = ({ classes, ...other }) => (
    <Typography {...other} className={classes.text} />
)

const StyledText = withStyles(textStyles)(Text)

const styles = (theme) => {
    return createStyles({
        link: {
            textDecoration: 'none',
            display: 'block',
            '&:hover': {
                visibility: 'visible',
                opacity: '1',
                display: 'block'
            },
        },
        item:{
margin:"1em 0"
        },

        active: {
            '& p': {
                fontWeight: 'bold',
                borderColor: 'rgba(0, 0, 0, 1)',
            },
            '&:hover p': {
                borderColor: theme.palette.primary.main,
            },
        },
    })
}

const NavMenu = ({ classes, ...other }) => (
    //   <PageLink
    //     {...other}
    //     className={classes.link}
    //     activeClassName={classes.active}
    //     Component={StyledText}
    //   />
    <ul {...other}
        className={classes.link}
        activeClassName={classes.active}>
        <li className={classes.item}>About</li>
        <li className={classes.item}>Careers</li>
    </ul>
)

export default withStyles(styles)(NavMenu)
