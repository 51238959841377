import React from 'react'
import {
  Container as BaseContainer,
  createStyles,
  withStyles,
} from '@material-ui/core'

export const getContainerStyles = (theme) => ({
  container: {
    maxWidth: '53rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '805px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '77rem',
    },
  },
})

const styles = (theme) => {
  const classesAndStyles = getContainerStyles(theme)
  return createStyles(classesAndStyles)
}

export const ContentContainer = ({ classes, ...other }) => (
  <BaseContainer className={classes.container} {...other} />
)

export default withStyles(styles)(ContentContainer)
