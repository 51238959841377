import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import PageLink from '../../../PageLink'

const textStyles = (theme) => {
  return createStyles({
    text: {
      fontSize: '0.75rem',
      fontWeight: 500,
      color: '#F2E9EE',
      [theme.breakpoints.up('md')]: {
        fontSize: '0.875rem',
      },
      textAlign: 'top',
    },
  })
}

const Text = ({ classes, ...other }) => (
  <Typography className={classes.text} {...other} />
)

const StyledText = withStyles(textStyles)(Text)

const styles = () => {
  return createStyles({
    item: {
      listStyle: 'none',
      '& + &': {
        marginTop: '0.8125rem',
      },
    },
    link: {
      textDecoration: 'none',
    },
  })
}

const FooterItem = ({ classes, ...other }) => {
  return (
    <li className={classes.item}>
      <PageLink {...other} className={classes.link} Component={StyledText} />
    </li>
  )
}

export default withStyles(styles)(FooterItem)
