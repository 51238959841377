import React, { Component } from 'react'
import { createStyles, withStyles, Button } from '@material-ui/core'
import Image from 'gatsby-image'

export const getStoreLinkStyles = (theme) => ({
  button: {
    padding: 0,
    position: 'relative',
    width: '5.25rem',
    height: '1.5625rem',
    [theme.breakpoints.up('lg')]: {
      width: '6.25rem',
      height: '1.8544rem',
    },
    '& + &': {
      marginLeft: '0.625rem',
    },
  },
  image: {
    width: '100%',
  },
})

const styles = (theme) => {
  const storeLinkStyles = getStoreLinkStyles(theme)
  return createStyles(storeLinkStyles)
}

export class StoreLink extends Component {
  render() {
    const { classes, imageSrc, href, label } = this.props
    return (
      <Button href={href} className={classes.button}>
        {imageSrc && (
          <Image
            className={classes.image}
            fluid={imageSrc.childImageSharp.fluid}
            alt={label}
          />
        )}
      </Button>
    )
  }
}

export default withStyles(styles)(StoreLink)
