import React, { useEffect } from 'react'
import { Hidden, AppBar, Toolbar, withStyles } from '@material-ui/core'
import ReactModal from 'react-modal'

import Links from './Links'
import SignUp from './SignUp'
import Logo from './Logo'
import PageContainer from '../PageContainer'
import SideMenu from './SideMenu'

export const navPadding = {
  right: '1.4375rem',
  left: '1.1875rem',
  vertical: '0.75rem',
  verticalMd: '1rem',
}

const getNavStyles = (theme) => {
  const backgroundColor = '#000000'
  const topOffset = '1rem'
  const getNavPaddingTop = (padding) => `calc(${padding} + ${topOffset})`
  return {
    appbar: {
      // borderBottom: '0.0313rem solid rgba(244, 246, 249, 1)',
      paddingLeft: navPadding.left,
      paddingRight: navPadding.right,
      paddingBottom: navPadding.vertical,
      paddingTop: getNavPaddingTop(navPadding.vertical),
      backgroundColor,
      boxShadow: 'none',
      fontFamily: 'Montserrat',
      top: `-${topOffset}`,
      [theme.breakpoints.up('md')]: {
        padding: `${getNavPaddingTop(navPadding.verticalMd)} 0 0`,
      },
    },
    toolbar: {
      backgroundColor,
      minHeight: 'auto',
      padding: 0,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-end',
      },
    },
    background: {
      // backdropFilter: 'saturate(50%) blur(1.25rem)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      // backgroundColor: 'rgba(255,255,255,0.8)',
    },
    sidebar: {
      paddingBottom: "1rem",
      display: 'flex',
      position: 'relative',
      paddingRight: '20px',
      [theme.breakpoints.up('xs')]: {
        paddingBottom: "0px",
      },
      [theme.breakpoints.up('sm')]: {
        paddingBottom: "1rem",
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }
  }
}

const Nav = ({ classes, onOpen, openQrModal }) => {
  const id = 'nav'

  const getNav = () => {
    return document.getElementById(id)
  }

  const setShadow = (value) => {
    const nav = getNav()
    nav.style.boxShadow = value
  }

  const addShadow = () => setShadow('0 0.25rem 0.5rem rgba(0, 40, 100, 0.08)')

  const removeShadow = () => setShadow('')

  const getTopDistance = (element) =>
    element.getBoundingClientRect().top + window.scrollY

  const getIfShouldShowShadow = () => {
    const nav = getNav()
    return getTopDistance(nav) > 0
  }

  const handleScroll = () => {
    const showShadow = getIfShouldShowShadow()
    if (showShadow) {
      return addShadow()
    }
    return removeShadow()
  }

  const watchNav = () => {
    document.addEventListener('scroll', handleScroll)
  }

  useEffect(watchNav, [])

  return (
    <AppBar className={classes.appbar} position="fixed">
      <div id={id} className={classes.background} />
      <Toolbar className={classes.toolbar}>
        <PageContainer>
          <div className={classes.content}>
            <div className={classes.wrapper}>
              <Logo />
              <Hidden mdDown>
                <a href="https://www.producthunt.com/posts/avocado-finance?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-avocado&#0045;finance" target="_blank">
                  <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=336488&theme=dark" alt="Avocado&#0032;Finance - Smartest&#0032;way&#0032;to&#0032;manage&#0032;your&#0032;finances | Product Hunt" style={{ width: '170px', height: '54px', marginLeft: '20px' }} />
                </a>
              </Hidden>
            </div>
            <Hidden xsDown>
              <Links
                openQrModal={openQrModal}
              />
            </Hidden>
            <Hidden smUp>
              <div className={classes.sidebar}>
                <SignUp
                  onOpen={onOpen}
                  openQrModal={openQrModal}
                />
                <SideMenu />
              </div>
            </Hidden>
          </div>
        </PageContainer>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(getNavStyles)(Nav)
