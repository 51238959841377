import React from 'react'
import { Button, createStyles, withStyles } from '@material-ui/core'
import Image from 'gatsby-image'

const styles = () => {
  const size = '2.0625rem'
  const imageSizes = {
    width: size,
    height: size,
  }
  return createStyles({
    item: {
      listStyle: 'none',
      ...imageSizes,
      '& + &': {
        marginLeft: '0.4375rem',
      },
    },
    button: {
      position: 'relative',
      padding: 0,
      minWidth: 0,
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      ...imageSizes,
    },
  })
}

const Item = ({ classes, href, src, alt }) => {
  return (
    <li className={classes.item}>
      <Button className={classes.button} href={href}>
        {src && (
          <Image
            fluid={src.childImageSharp.fluid}
            alt={alt}
            className={classes.image}
          />
        )}
      </Button>
    </li>
  )
}

export default withStyles(styles)(Item)
