import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { withStyles } from '@material-ui/core'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'gatsby'
import { links } from '../../../constants/links'
import Logo from './Logo'
import SignUp from './SignUp'
import networth from '../../../images/networth-icon.png'
import savings from '../../../images/savings-icon.png'
import financial from '../../../images/financial-icon.png'
import investment from '../../../images/investment-icon.png'
import sync from '../../../images/sync-icon.png'
import transaction from '../../../images/transaction-icon.png'
import house from '../../../images/house-icon.png'
import emergency from '../../../images/emergency-icon.png'
import retirement from '../../../images/retirement-icon.png'
import refinance from '../../../images/refinance-icon.png'

const getNavStyles = (theme) => {
    const backgroundColor = 'transparent'
    const topOffset = '1rem'
    const getNavPaddingTop = (padding) => `calc(${padding} + ${topOffset})`
    return {
        container: {
            width: '40px',
            position: 'absolute',
            right: '-30px',
            top: "-5px",
            fontFamily: "Montserrat",
            color: "#F0EDEF",
            backgroundColor: '#000000',
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 40px 10px 10px",
            backgroundColor: '#000000',
            fontFamily: "Montserrat",
        },
        close: {
            position: "absolute!important",
            right: "5px",
            top: "5px",
            color: "#ffffff!important"
        },
        chevron: {
            color: "#fff!important",
        },
        heading: {
            padding: "10px 20px",
            backgroundColor: "#101010",
            color: '#F0EDEF',
            fontSize: "9px",
            fontWeight: 600,
            fontFamily: "Montserrat",
        },
        hr: {
            borderColor: "#1A1A1A!important"
        },
        icon: {
            width: 22
        },
        list: {
            color: '#F0EDEF',
            fontFamily: 'Montserrat',
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
            backgroundColor: '#000000',
        }
    }
}

const SideMenu = ({ classes }) => {
    const theme = useTheme()
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const drawerWidth = '100%';
    return (
        <div className={classes.container}>
            <IconButton
                color="default"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, color: '#000', ...(open && { display: 'none' }) }}
            >
                <MenuIcon
                    style={{ fill: "white" }}
                />
            </IconButton>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        // boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <div className={classes.header}>
                    <Link to="/" onClick={handleDrawerClose}>
                        <Logo />
                    </Link>
                    <SignUp />
                    <IconButton onClick={handleDrawerClose} className={classes.close}>
                        <CloseIcon style={{ fill: "white" }} />
                    </IconButton>
                </div>
                {/* <Divider /> */}
                <List className={classes.list}>
                    {[{ title: 'Download', link: links.download }].map((text, index) => (
                        <>
                            <Link to={text.link} key={index} style={{ textDecoration: "none" }}>
                                <ListItem button >
                                    <p style={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        lineHeight: '14px',
                                        color: '#ffffff',
                                        flex: 1,
                                    }}>
                                        {text.title}
                                    </p>
                                    <ListItemIcon style={{ justifyContent: "flex-end" }}>
                                        <ChevronRightIcon className={classes.chevron} />
                                    </ListItemIcon>
                                </ListItem>
                            </Link>
                            <Divider className={classes.hr} />
                        </>
                    ))}
                </List>
                <div className={classes.heading} >FEATURES</div>
                {/* <Divider /> */}
                <List className={classes.list}>
                    {[
                        { title: 'Net Worth', link: '/features/networth', icon: networth },
                        { title: 'Savings Plan', link: '/features/savings-plan', icon: savings },
                        { title: 'Transactions', link: '/features/transactions', icon: transaction },
                        { title: 'Financial Future', link: '/features/financial-future', icon: financial },
                        { title: 'Sync Accounts', link: '/features/sync-accounts', icon: sync },
                        { title: 'Investments', link: '/features/investments', icon: investment },
                        { title: 'Emergency Fund', link: '/features/emergency-fund', icon: emergency },
                        { title: 'Retirement', link: '/features/retirement', icon: retirement },
                        { title: 'House', link: '/features/house', icon: house },
                        { title: 'Refinance', link: '/features/refinance', icon: refinance }
                    ].map((text, index) => (
                        <>
                            <Link to={text.link} key={index} style={{ textDecoration: "none" }}>
                                <ListItem button>
                                    <p style={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        lineHeight: '14px',
                                        color: '#ffffff',
                                        flex: 1,
                                    }}>
                                        {text.title}
                                    </p>
                                    {/* <img src={text.icon} className={classes.icon} /> */}
                                    <ListItemIcon style={{ justifyContent: "flex-end" }}>
                                        <ChevronRightIcon className={classes.chevron} />
                                    </ListItemIcon>
                                </ListItem>
                            </Link>
                            <Divider className={classes.hr} />
                        </>
                    ))}
                </List>
                <div className={classes.heading} >COMPANY</div>
                {/* <Divider /> */}
                <List className={classes.list}>
                    {[{ title: 'About', link: links.about }, { title: 'Careers', link: links.careers }].map((text, index) => (
                        <>
                            <Link to={text.link} key={index} style={{ textDecoration: "none", }}>
                                <ListItem button>
                                    <p style={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 600,
                                        textDecoration: 'none',
                                        color: '#F0EDEF',
                                        flex: 1,
                                        fontSize: 14,
                                    }}>
                                        {text.title}
                                    </p>
                                    <ListItemIcon style={{ justifyContent: "flex-end" }}>
                                        <ChevronRightIcon className={classes.chevron} />
                                    </ListItemIcon>
                                </ListItem>
                            </Link>
                            <Divider className={classes.hr} />
                        </>
                    ))}
                </List>
            </Drawer>
        </div>
    );
}
export default withStyles(getNavStyles)(SideMenu)