import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import PageLink from '../../../../PageLink'
import { navPadding } from '../..'

const textStyles = (theme) => {
  return createStyles({
    text: {
      fontWeight: 'inherit',
      fontSize: '1rem',
      padding: `0 1rem ${navPadding.verticalMd}`,
      borderBottom: `0.0625rem solid transparent`,
      display: 'flex',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  })
}

const Text = ({ classes, ...other }) => (
  <Typography {...other} className={classes.text} />
)

const StyledText = withStyles(textStyles)(Text)

const styles = (theme) => {
  return createStyles({
    link: {
      textDecoration: 'none',
      display: 'block',
    },
    active: {
      '& p': {
        fontWeight: 'bold',
        // borderColor: 'rgba(0, 0, 0, 1)',
      },
      '&:hover p': {
        // borderColor: theme.palette.primary.main,
      },
    },
  })
}

const NavPageLink = ({ classes, ...other }) => (
  <PageLink
    {...other}
    className={classes.link}
    activeClassName={classes.active}
    Component={StyledText}
  />
)

export default withStyles(styles)(NavPageLink)
