import React, { useState } from 'react'
import { createStyles, withStyles, Button } from '@material-ui/core'
import Modal from 'react-modal';

import CloseButton from './pinkCloseButton.png'

import iosLoader from '../../images/ios-loader.gif'

const avocadoFinanceUrl = process.env.GATSBY_AF_URL
const countApiName = process.env.GATSBY_COUNT_API_KEY
const waitlistName = process.env.GATSBY_WAITLIST_KEY
const emailValidateApiKey = process.env.GATSBY_WHOIS_API_KEY
const linkedInUrl = process.env.LINKED_IN_URL

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
const isEmailAddress = (x) =>
  /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i.test(
    x,
  )

const apiUrl = `https://avocado-finance.app.waitlisted.co/api/v2/reservations`

const customStyles = {
  content: {
    top: '60px',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'absolute',
    height: '100%',
    width: 'auto',
  }
}

const styles = (theme) => {
  return createStyles({
    header: {
      fontFamily: 'Montserrat',
      fontSize: 24,
      fontWeight: '700',
      margin: '45px 0 35px 0',
    },
    text: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: '400',
      margin: 0,
      lineHeight: '26px',
      margin: '0 0 25px 0',
    },
    input: {
      borderStyle: 'solid',
      borderRadius: 50,
      border: '1px solid #FF0080',
      padding: '15px 25px',
      fontFamily: 'Montserrat',
      fontSize: 16,
      color: '#333333',
      fontWeight: '400',
      margin: '0 0 35px 0',
      width: '100%',
      boxSizing: 'border-box',
      boxShadow: 'none !important'
    },
    closeButton: {
      backgroundColor: 'transparent',
      border: 'none',
      position: 'absolute',
      right: 0,
      cursor: 'pointer',
    },
    closeButtonImage: {
      width: 20,
    },
    signUpButton: {
      padding: '15px 25px',
      border: 0,
      borderRadius: 100,
      backgroundColor: '#FF0080',
      fontFamily: 'Montserrat',
      fontSize: 14,
      color: '#fff',
      fontWeight: '700',
      cursor: 'pointer',
      width: '100%',
      boxSizing: 'border-box',
    },
    errorMessage: {
      margin: '0',
      fontFamily: 'Montserrat',
      fontWeight: '400',
      fontSize: 12,
      color: '#FF0020',
      position: 'absolute',
      left: '25px',
      top: '60px',
    },
    confirmedText: {
      fontFamily: 'Montserrat',
      fontWeight: '800',
      fontSize: 14,
      textAlign: 'center',
    },
    loader: {
      width: '25px'
    }
  })
}

const SignupModal = ({ classes, isOpen, onClose }) => {
  const [emailValue, setEmailValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const sendGridVerification = (emailAddress, code) => {
    fetch('https://af-sendgrid-proxy.herokuapp.com/v3/mail/send', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        personalizations: [
          {
            to: [
              {
                email: emailAddress,
              },
            ],
            dynamic_template_data: {
              refCode: code,
              afUrl: avocadoFinanceUrl,
              linkedInUrl: linkedInUrl,
            },
          },
        ],
        asm: {
          group_id: 121276,
        },
        nickname: 'Avocado Finance',
        from: {
          email: 'support@avocadofinance.io',
          name: 'Avocado Finance',
        },
        reply_to: {
          email: 'support@avocadofinance.io',
          name: 'Avocado Finance',
        },
        address: '944 Ashbridge Lane',
        address_2: '',
        city: 'Harbor City',
        state: 'California',
        zip: '90710',
        country: 'United States',
        template_id: 'd-395f100ed59e4d7db3a8386157600dee',
      }),
    })
  }

  const createReservation = () => {
    window.dataLayer.push({ event: 'clickedSignUp' })

    return fetch(apiUrl, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        api_key: '5ce7b7c31c8a1a42d70e81b4d436cc38',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({
        email: emailValue,
        affiliate: '123',
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          setIsLoading(false)
          setIsConfirmed(false)
          setErrorMessage('You are already on the waitlist.')
        } else {
          sendGridVerification(emailValue, data.affiliate)
          setIsLoading(false)
          setIsConfirmed(true)
        }
      })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isLoading && !isConfirmed) {
      if (isEmailAddress(emailValue)) {
        setErrorMessage(false)
        setIsLoading(true)
        createReservation()
      } else {
        setIsLoading(false)
        setErrorMessage('Please enter a valid email address.')
      }
    } else {
      return
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
    >
      <div style={{ position: 'relative' }}>
        <button onClick={onClose} className={classes.closeButton}>
          <img className={classes.closeButtonImage} src={CloseButton} />
        </button>
        <h2 className={classes.header}>Sign Up</h2>
        <p className={classes.text}>You are signing up to be notified by email when our product launches.</p>
        {
          isConfirmed
            ? (
              <p className={classes.confirmedText}>You have been added to our waitlist!</p>
            )
            : (
              <div style={{ position: 'relative' }}>
                <input
                  className={classes.input}
                  placeholder="Enter Email"
                  value={emailValue}
                  onChange={(event) => {
                    const { value } = event.target;
                    setEmailValue(value)
                  }}
                  onKeyDown={handleKeyDown}
                  style={{
                    WebkitAppearance: 'none'
                  }}
                />
                {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
              </div>
            )
        }
        <button className={classes.signUpButton} onClick={onSubmit}>
          {
            isLoading
              ? <img className={classes.loader} src={iosLoader} />
              : isConfirmed ? 'Done' : 'Sign Up'
          }
        </button>
      </div>
    </Modal>
  )
}

export default withStyles(styles)(SignupModal)
