import { createStyles, withStyles } from '@material-ui/core'
import Logo from '../Logo'

const styles = (theme) => {
  return createStyles({
    image: {
      width: '6.5625rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '0.8125rem',
        width: '10.25rem',
      },
    },
  })
}

export default withStyles(styles)(Logo)
