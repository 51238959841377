import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import { flexBreakpoint } from './styles'
import Logo from '../../Logo'

const styles = (theme) => {
  return createStyles({
    image: {
      width: '100%',
      maxWidth: '17.875rem',
      [theme.breakpoints.up('xs')]: {
        width: '10.0731rem',
        maxWidth: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: '10.0731rem',
        maxWidth: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
        maxWidth: '17.875rem',
      },
      // [theme.breakpoints.up(flexBreakpoint)]: {
      // },
    },
    container: {
      marginBottom: '1.375rem',
      paddingLeft: '2.25rem',
      paddingRight: '2.375rem',
      [theme.breakpoints.up(flexBreakpoint)]: {
        marginBottom: '2.625rem',
        padding: 0,
      },
    },
  })
}

const FooterLogo = ({ classes }) => {
  return (
    <div className={classes.container}>
      <Logo classes={{ image: classes.image }} />
    </div>
  )
}

export default withStyles(styles)(FooterLogo)
