import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const Logo = ({ classes }) => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "logo" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Link to="/">
      {image.childImageSharp && (
        <Image
          fluid={image.childImageSharp.fluid}
          alt="Avocado Finance"
          className={classes.image}
        />
      )}
    </Link>
  )
}

export default Logo
