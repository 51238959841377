import React from 'react'
import { links } from '../../../constants/links'

import Section from './Section'
import Links from './Links'
import Link from './Links/Item'

const Support = () => (
  <Section title="Support">
    <Links>
      {/* <Link to={links.contact}>Contact</Link> */}
      <Link to='https://intercom.help/avocado-finance-196bea118860/en' target="_blank">Help Center</Link>
      <Link to={links.security}>Security</Link>
    </Links>
  </Section>
)

export default Support
