import React, { useState, useEffect } from 'react'
import { createStyles, withStyles, Typography, Box, Grid, Button } from '@material-ui/core'
import { links } from '../../../../constants/links'
import NavLink from './NavItem'
import NavDropdown from './NavDropdown'
import SignUp from '../SignUp'
import { Link } from 'gatsby'
import networth from '../../../../images/networth-icon.png'
import savings from '../../../../images/savings-icon.png'
import financial from '../../../../images/financial-icon.png'
import investment from '../../../../images/investment-icon.png'
import sync from '../../../../images/sync-icon.png'
import transaction from '../../../../images/transaction-icon.png'
import house from '../../../../images/house-icon.png'
import emergency from '../../../../images/emergency-icon.png'
import retirement from '../../../../images/retirement-icon.png'
import refinance from '../../../../images/refinance-icon.png'
// import SideMenu from '../SideMenu'

import SmallArrow from '../small-arrow.svg'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { navPadding } from '../'
const styles = (theme) => {
  return createStyles({
    list: {
      display: 'flex',
      padding: 0,
      margin: 0,
      alignItems: 'flex-end',
      fontFamily: 'Montserrat',
    },
    container: {
      padding: '10px',
      backgroundColor: '#101010',
      borderRadius: '19px',
    },
    text: {
      fontFamily: 'Montserrat',
      fontWeight: 'inherit',
      fontSize: '1rem',
      // padding: `0 1rem ${navPadding.verticalMd}`,
      borderBottom: `0.0625rem solid transparent`,
      display: 'flex',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    link: {
      textDecoration: 'none',
      display: 'block',
      marginTop: "10px"
    },
    active: {
      '& p': {
        fontWeight: 'bold',
        // borderColor: 'rgba(0, 0, 0, 1)',
      },
      '&:hover p': {
        // borderColor: theme.palette.primary.main,
      },
    },

    item: {
      color: "#000",
      display: "block",
      transitionDuration: "0.5s",
      padding: '0 0.8rem 1rem',
      display: "flex",
      position: "relative",

      '& ul': {
        visibility: 'hidden',
        opacity: '0',
        position: 'absolute',
        transition: 'all 0.5s ease',
        marginTop: '1rem',
        left: '0px',
        display: 'none',
        top: "15px",
        listStyle: "none",
        backgroundColor: '#101010',
        border: '1px solid #E8E8E8',
        boxSizing: 'border-box',
        boxShadow: '4px 4px 15px 1px rgba(44, 51, 115, 0.1)',
        borderRadius: '19px',
        // width: '103px',
        // height: '106px',
        padding: "10px 10px",
        textAlign: "center"
        // boxShadow:"rgba(0, 40, 100, 0.16) 0px 16px 24px 0px"
      },
      '&:hover': {
        cursor: 'pointer',
        '& ul': {
          visibility: 'visible',
          opacity: '1',
          display: 'block'
        }
      },
    },
    featuresMenu: {
      width: "650px",
      left: "-100px!important",
      borderRadius: '19px',
      [theme.breakpoints.up('sm')]: {
        left: "-170px!important",
      },
      [theme.breakpoints.up('md')]: {
        left: "-100px!important",
      },
    },
    row: {
      '& + &': {
        [theme.breakpoints.up('md')]: {
          // marginTop: '10rem',
        },
        [theme.breakpoints.up('lg')]: {
          // marginTop: '9.75rem',
        },
      },
    },
    feature: {
      '&:hover': {
        background: '#2B2B2B',
        // background: '#FBFBFB',
        borderRadius: '10px',
        '& .smallArrow': {
          opacity: "1",
          transform: 'translateX(3px)'
        }
      },
      '& a': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "left",
        margin: "10px 0",
        textDecoration: "none",
      },

    },
    featureHeader: {
      display: "flex",
      alignItems: 'center',
      // color: '#002864',
      color: '#000',
      // fontSize: '16px',
      // fontWeight:"500",
      '& .smallArrow': {
        opacity: "0",
        color: "#ff0080",
        // transition:"all 0.15s ease-out",
        transition: 'transform 0.3s ease',
        transform: 'translateX(0px)'
      }
    },
    icon: {
      marginRight: "10px",
      width: "30px",
      height: "30px"
    },
    title: {
      color: '#F2E9EE',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '20px',
    },
    description: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#F2E9EE',
    },
    signupBtn: {
      // paddingBottom: "0.5rem"
    }
  })
}

const Links = ({ classes, openQrModal }) => {
  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    setIsPhone(window.innerWidth <= 599)
    window.addEventListener("resize", handleResize);
  }, [])

  const handleResize = (e) => {
    setIsPhone(window.innerWidth <= 599)
  }

  return (
    <ul className={classes.list}>
      <li className={classes.item}>
        <Typography className={classes.text}> Features <KeyboardArrowDownIcon /></Typography>
        <ul className={classes.featuresMenu}>

          {/* <li className={classes.item}>About</li>
          <li className={classes.item}>Careers</li> */}
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/networth">
                  <img src={networth} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>
                        Net Worth
                      </Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>A comprehensive view of your current finances</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/investments">
                  <img src={investment} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>Investments</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>Track and analyze your investments across all of your accounts in one place</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/savings-plan">
                  <img src={savings} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>Savings Plan</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>Create a savings plan and see what that means for your future</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/emergency-fund">
                  <img src={emergency} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>Emergency Fund</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>Know that you have one in place and monitor your progress</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/transactions">
                  <img src={transaction} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>Transactions</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>See all of your transactions across all of your accounts in a single feed</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/retirement">
                  <img src={retirement} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>Retirement</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>Know what age and year you can realistically retire</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/financial-future">
                  <img src={financial} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>Financial Future</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>Your financial future with clear insights to help you plan</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/house">
                  <img src={house} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>House</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>Understand your purchasing power to buy a house</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/sync-accounts">
                  <img src={sync} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>Sync Accounts</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>Add all your accounts for a full financial picture</Typography>

                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={6} className={classes.feature}>
                <Link to="/features/refinance">
                  <img src={refinance} className={classes.icon} />
                  <div>
                    <div className={classes.featureHeader}>
                      <Typography variant="h6" className={classes.title}>Refinance</Typography>
                      <span className={`smallArrow`}><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>

                    </div>
                    <div className={classes.featureDescription}>
                      <Typography className={classes.description}>Connect your loans to know if you can save money by refinancing</Typography>
                    </div>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </ul>
      </li>
      <li className={classes.item}>
        <Link to={links.download} className={classes.link}>
          <Typography className={classes.text}> Download </Typography>
        </Link>
      </li>
      <li className={classes.item}>
        <Typography className={classes.text}> Company <KeyboardArrowDownIcon /></Typography>
        <ul>
          <li>
            <Link to={links.about} className={classes.link}>
              <Typography className={classes.text}> About </Typography>
            </Link>
          </li>
          <li>
            <Link to={links.careers} className={classes.link}>
              <Typography className={classes.text}> Careers </Typography>
            </Link></li>
        </ul>
      </li>
      {/* <NavLink to={links.features}>Features <KeyboardArrowDownIcon /> </NavLink> */}
      {/* <NavLink to={links.pricing}>Pricing</NavLink> */}
      {/* <NavLink to={links.download}>Download</NavLink> */}
      {/* <NavLink to={links.about}>Company <KeyboardArrowDownIcon /> </NavLink> */}
      {/* <NavDropdown >Company <KeyboardArrowDownIcon /> </NavDropdown> */}
      {/* {!isPhone && <li className={classes.item}><Button onClick={() => { console.info('hello') }}><SignUp /></Button></li>} */}
      <li className={classes.item}>
        <Link to={isPhone ? 'https://avocadofinance-alternate.app.link' : null}>
          <SignUp openQrModal={openQrModal} />
        </Link>
      </li>
    </ul>
  )
}

export default withStyles(styles)(Links)
