import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import Top from './Top'
import Container from '../PageContainer'
import BaseComingSoon from './ComingSoon/index'
import Product from './Product'
import Company from './Company'
import Support from './Support'
import Legal from './Legal'
import { flexBreakpoint } from './Top/styles'

export const footerMarginTop = '6.0625rem'

const styles = (theme) => {
  return createStyles({
    footer: {
      // marginTop: footerMarginTop,
      backgroundColor: '#000000',
      padding: '4.375rem 0 3.9375rem',
    },
    content: {
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    body: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    links: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      paddingLeft: '2.625rem',
      paddingRight: '1rem',
      maxWidth: '18.875rem',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
        margin: 0,
        padding: 0,
        maxWidth: '100%',
      },
    },
  })
}

const Footer = ({ classes, ComingSoon = BaseComingSoon }) => {
  return (
    <footer className={classes.footer}>
      <Container>
        <div className={classes.content}>
          <Top />
          <div className={classes.body}>
            <ComingSoon />
            <div className={classes.links}>
              <Product />
              <Company />
              <Support />
              <Legal />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default withStyles(styles)(Footer)
