import React from 'react'
import { links } from '../../../constants/links'

import Section from './Section'
import Links from './Links'
import Link from './Links/Item'

const Company = () => (
  <Section title="Company">
    <Links>
      <Link to={links.about}>
        About
      </Link>
      <Link to={links.careers}>
        Careers
      </Link>
    </Links>
  </Section>
)

export default Company
