import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import StoreLink from './StoreLink'

const StoreLinks = ({ Item = StoreLink }) => {
  const { appStoreImage, googlePlayImage } = useStaticQuery(graphql`
    {
      appStoreImage: file(name: { eq: "availableOnApple" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      googlePlayImage: file(name: { eq: "google-play" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <Item imageSrc={appStoreImage} label="App Store" href="https://avocadofinance-alternate.app.link" />
      {/* <Item imageSrc={googlePlayImage} label="Google Play" href="#" /> */}
    </>
  )
}

export default StoreLinks
