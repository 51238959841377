import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

const styles = (theme) => {
  return createStyles({
    list: {
      padding: 0,
      marginBottom: '2.25rem',
      marginTop: 0,
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
  })
}

const Links = ({ classes, children }) => {
  return <ul className={classes.list}>{children}</ul>
}

export default withStyles(styles)(Links)
