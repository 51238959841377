import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'

import Item from './Item'

const styles = () => {
  return createStyles({
    list: {
      padding: 0,
      width: 'auto',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
    },
  })
}

const List = ({ classes }) => {
  const { facebook, instagram, twitter } = useStaticQuery(graphql`
    {
      twitter: file(name: { eq: "twitter" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      instagram: file(name: { eq: "instagram" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      facebook: file(name: { eq: "facebook" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <ul className={classes.list}>
      <Item src={facebook} alt="Facebook" href="https://www.facebook.com/avocadofinance" />
      <Item src={instagram} alt="Instagram" href="https://www.instagram.com/avocadofinanceapp/" />
      <Item src={twitter} alt="Twitter" href="https://twitter.com/avocadofinance" />
    </ul>
  )
}

export default withStyles(styles)(List)
