export const links = {
  pricing: '/pricing',
  download: '/download',
  about: '/about',
  signUp: '/sign-up',
  features: '/',
  careers: '/careers',
  faq: '/faq',
  contact: '/contact',
  security: '/security',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy',
  features: '/features',
}
