import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

export const getSectionStyles = (theme) => ({
  section: {
    marginBottom: '2.25rem',
    width: '50%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      maxWidth: '8.5rem',
    },
    '& + &': {
      [theme.breakpoints.up('md')]: {
        marginLeft: '3rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: '4.6875rem',
      },
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: 700,
    marginBottom: '1.3125rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '1.875rem',
      fontWeight: 600,
    },
  },
})

const styles = (theme) => {
  const sectionStyles = getSectionStyles(theme)
  return createStyles(sectionStyles)
}

export const Section = ({ classes, children, title, ...other }) => {
  return (
    <div className={classes.section} {...other}>
      <Typography className={classes.title} variant="h2">
        {title}
      </Typography>
      {children}
    </div>
  )
}

export default withStyles(styles)(Section)
