import React from 'react'
import { links } from '../../../constants/links'

import Section from './Section'
import Links from './Links'
import Link from './Links/Item'

const Legal = () => (
  <Section title="Legal">
    <Links>
      <Link to={links.termsOfUse}>Terms of Use</Link>
      <Link to={links.privacyPolicy}>Privacy Policy</Link>
    </Links>
  </Section>
)

export default Legal
