import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import Link from './Link'

const getLinkWidthStyle = (styles, width, index) => {
  const position = index + 1
  return {
    ...styles,
    [`&:nth-of-type(4n + ${position})`]: {
      width,
    },
  }
}

const getLinkWidthStyles = () => {
  const widths = ['5.1494rem', '7.3563rem', '5.1019rem']
  return widths.reduce(getLinkWidthStyle, {})
}

const styles = () => {
  return createStyles({
    item: {
      marginLeft: '2rem',
      listStyle: 'none',
      '&:last-of-type a': {
        paddingRight: 0,
      },
      ...getLinkWidthStyles(),
    },
    text: {
      fontSize: '0.875rem',
    },
  })
}

const NavLink = ({ classes, ...other }) => {
  return (
    <li className={classes.item}>
      <Link className={classes.text} {...other} />
    </li>
  )
}

export default withStyles(styles)(NavLink)
