import React from 'react'
import { Container, createStyles, withStyles } from '@material-ui/core'
import { getContainerStyles } from '../ContentContainer'

const styles = (theme) => {
  const containerStyles = getContainerStyles(theme)
  return createStyles({
    container: {
      padding: 0,
      ...containerStyles.container,
    },
  })
}

const PageContainer = ({ classes, ...other }) => {
  return <Container className={classes.container} {...other}></Container>
}

export default withStyles(styles)(PageContainer)
