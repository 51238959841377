import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import NavMenu from './NavMenu'

const getLinkWidthStyle = (styles, width, index) => {
    const position = index + 1
    return {
        ...styles,
        [`&:nth-of-type(4n + ${position})`]: {
            width,
        },
    }
}

const getLinkWidthStyles = () => {
    const widths = ['5.1494rem', '7.3563rem', '5.1019rem']
    return widths.reduce(getLinkWidthStyle, {})
}

const styles = () => {
    return createStyles({
        item: {
            color: "#000",
            display: "block",
            transitionDuration: "0.5s",
            padding: '0 1rem 1rem',
            display: "flex",
            position: "relative",

            '& ul': {
                visibility: 'hidden',
                opacity: '0',
                position: 'absolute',
                transition: 'all 0.5s ease',
                marginTop: '1rem',
                left: '0',
                display: 'none',
                top: "10px",
                listStyle: "none",
                background: '#FFFFFF',
                border: '0.5px solid #E8E8E8',
                boxSizing: 'border-box',
                boxShadow: '4px 4px 15px 1px rgba(44, 51, 115, 0.1)',
                borderRadius: '19px',
                width: '103px',
                height: '106px',
                padding: "10px",
                textAlign: "center"
            },
            '&:hover': {
                cursor: 'pointer',
                '& ul': {
                    visibility: 'visible',
                    opacity: '1',
                    display: 'block'
                }
            },
            // '&:hover ul': {
            //     visibility: 'visible',
            //     opacity: '1',
            //     display: 'block'
            // },
        }
    })
}

const NavDropdown = ({ classes, children }) => {
    console.log("other", children)
    return (
        <li className={classes.item}>
            {children}
            <NavMenu className={classes.text} />
        </li>
    )
}

export default withStyles(styles)(NavDropdown)
